import qs from "qs";
import { GET_DOCUMENTS } from "./types";
import { apiReport, errorReport } from "../helpers/reporter";

export const getDocuments = params => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/documents", {
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params);
      }
    });

    await dispatch({ type: GET_DOCUMENTS, payload: response.data.payload });
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createDocument = (file, setPercentage) => async (
  dispatch,
  getState,
  api
) => {
  try {
    const data = await new FormData();
    await data.append("file", file);

    const config = {
      onUploadProgress: e => {
        setPercentage(Math.round((e.loaded * 100) / e.total));
      }
    };

    const response = await api.post("/api/v1/documents", data, config);

    await dispatch(getDocuments());

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removeDocument = ({ id }) => async (dispatch, getState, api) => {
  try {
    const response = await api.delete(`/api/v1/documents/${id}`);

    await dispatch(getDocuments());

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
