import {
  SET_NEW_PAGE,
  GET_PAGE,
  SET_PAGE_COMPONENT_CHANGES
} from "../actions/types";

const initialState = {
  meta: {
    title: "",
    description: ""
  },
  url: "",
  type: "",
  active: false,
  sitemap: {
    changefreq: "",
    priority: 0.5
  },
  social: {
    ogTitle: "",
    ogDescription: "",
    ogType: "",
    ogImage: {}
  },
  components: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_PAGE:
      return initialState;
    case SET_PAGE_COMPONENT_CHANGES:
      return {
        ...state,
        components: state.components.map((component, index) => {
          if (index === action.payload.index) {
            component.content = action.payload.content;
          }
          return component;
        }),
        changes: true
      };
    case GET_PAGE:
      return { ...state, ...action.payload, changes: false };
    default:
      return state;
  }
};
