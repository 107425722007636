import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import axios from "axios";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { loadableReady } from "@loadable/component";
import { CookiesProvider } from "react-cookie";
import Routes from "./common/Routes";
import reducers from "./common/reducers";

import UIkit from "uikit";
import UIkitIcons from "uikit/dist/js/uikit-icons";
import CustomIcons from "./custom-icons";
UIkit.use(UIkitIcons);
UIkit.use(CustomIcons);
window.UIkit = UIkit;

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
});

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.INIT_STATE;

// Allow the passed state to be garbage-collected
delete window.INIT_STATE;

const store = createStore(
  reducers,
  preloadedState,
  composeWithDevTools(applyMiddleware(thunk.withExtraArgument(axiosInstance)))
);

loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>{renderRoutes(Routes)}</BrowserRouter>
      </CookiesProvider>
    </Provider>,
    document.querySelector("#root")
  );
});

if (module.hot) {
  module.hot.accept();
}
