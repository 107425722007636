export const GET_ME = "GET_ME";

export const GET_NAVIGATIONS = "GET_NAVIGATIONS";
export const GET_NAVIGATIONS_PAGES = "GET_NAVIGATIONS_PAGES";
export const RESET_NODE = "RESET_NODE";

export const GET_PAGE = "GET_PAGE";
export const SET_NEW_PAGE = "SET_NEW_PAGE";
export const SET_PAGE_COMPONENT_CHANGES = "SET_PAGE_COMPONENT_CHANGES";

export const GET_MEDIA = "GET_MEDIA";

export const GET_DOCUMENTS = "GET_DOCUMENTS";

export const GET_COMPONENTS = "GET_COMPONENTS";

export const GET_EMPLOYEES = "GET_EMPLOYEES";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_PAGE = "GET_NEWS_PAGE";
export const SET_NEW_NEWS = "SET_NEW_NEWS";
export const GET_NEWS_CATEGORIES = "GET_NEWS_CATEGORIES";

export const GET_JOBS = "GET_JOBS";
export const GET_JOB_PAGE = "GET_JOB_PAGE";
export const SET_NEW_JOB = "SET_NEW_JOB";
export const GET_JOB_CATEGORIES = "GET_JOB_CATEGORIES";
export const GET_JOB_COMPANIES = "GET_JOB_COMPANIES";
export const GET_JOB_AREAS = "GET_JOB_AREAS";

export const GET_BREADCRUMB = "GET_BREADCRUMB";
export const SET_BREADCRUMB = "SET_BREADCRUMB";

export const GET_PRESS = "GET_PRESS";
export const GET_PRESS_PAGE = "GET_PRESS_PAGE";
export const SET_NEW_PRESS = "SET_NEW_PRESS";

export const SET_SHOW_COOKIES_CONS = "SET_SHOW_COOKIES_CONS";
