import {
  defaultFailureNotification,
  defaultSuccessNotification
} from "./UIkitNotifications";

export const apiReport = (response, auth) => {
  if (auth) {
    if (response.data && response.data.success) {
      defaultSuccessNotification(response.data.message);
      return response.data.payload;
    } else {
      defaultFailureNotification(response.data.message);
      return {};
    }
  }
};

export const supportReport = (auth, message) => {
  if (auth && Array.isArray(auth.role) && auth.role.includes("support")) {
    defaultSuccessNotification(message);
  }
};

export const errorReport = (error, auth) => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.message &&
    auth
  ) {
    defaultFailureNotification(error.response.data.message);
  }
};
