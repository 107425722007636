import React from "react";
import loadable from "@loadable/component";
import Backend from "./Backend";
import Page from "../frontend/pages/Page";
import NewsCreator from "../backend/pages/NewsCreator";
import PressCreator from "../backend/pages/PressCreator";
import JobCreator from "../backend/pages/JobCreator";
import JobsOverview from "../backend/pages/JobsOverview";
import NewsDetails from "../frontend/pages/NewsDetails";
import { runtimeConfig } from "./helpers/config";
import JobDetails from "../frontend/pages/JobDetails";

const App = loadable(() => import("./App"));

const Pages = loadable(() => import("../backend/pages/Pages"));
const Navigations = loadable(() => import("../backend/pages/Navigations"));
const NewsPressDatesOverview = loadable(() =>
  import("../backend/pages/NewsPressDatesOverview")
);

const Login = loadable(() => import("../frontend/pages/Login"));
const Register = loadable(() => import("../frontend/pages/Register"));
const NotFound = loadable(() => import("../frontend/pages/NotFound"));

export default [
  {
    component: Login,
    path: "/login",
  },
  {
    component: Register,
    path: "/register",
  },
  {
    ...Backend,
    routes: [
      {
        component: Navigations,
        path: "/navigations",
      },
      {
        component: Pages,
        path: "/pages/:id?",
      },
      {
        component: NewsPressDatesOverview,
        path: `/news-press-dates-overview`,
      },
      {
        component: PressCreator,
        path: `/b/press/:id?`,
      },
      {
        ...NewsCreator,
        path: `/news-creator`,
      },
      {
        ...JobsOverview,
        path: `/jobs-overview`,
        exact: true,
      },
      {
        ...JobCreator,
        path: `/job-creator`,
      },
      {
        component: App,
        routes: [
          {
            ...NewsDetails,
            path: `/${runtimeConfig.NEWS_URL_PREFIX}/:id`,
            exact: true,
          },
          {
            ...JobDetails,
            path: `/${runtimeConfig.JOB_URL_PREFIX}/:id`,
            exact: true,
          },
          {
            component: NotFound,
            path: "/404",
            exact: true,
          },
          {
            ...Page,
          },
        ],
      },
    ],
  },
];
