import { GET_JOB_CATEGORIES } from "./types";
import { apiReport, errorReport } from "../helpers/reporter";

export const getJobCategories = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/jobCategories");

    await dispatch({
      type: GET_JOB_CATEGORIES,
      payload: response.data.payload
    });

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createJobCategory = category => async (
  dispatch,
  getState,
  api
) => {
  try {
    const response = await api.post("/api/v1/jobCategories", category);

    await dispatch(getJobCategories());

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removeJobCategory = category => async (
  dispatch,
  getState,
  api
) => {
  try {
    await api.delete(`/api/v1/jobCategories/${category._id}`);

    await dispatch(getJobCategories());
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
