import { combineReducers } from "redux";
import auth from "./auth";
import navigations from "./navigations";
import page from "./page";
import navigationPages from "./navigationPages";
import media from "./media";
import components from "./components";
import documents from "./documents";
import employees from "./employees";
import news from "./news";
import newsPage from "./newsPage";
import newsCategories from "./newsCategories";
import jobs from "./jobs";
import jobPage from "./jobPage";
import jobCategories from "./jobCategories";
import jobCompanies from "./jobCompanies";
import breadcrumb from "./breadcrumb";
import press from "./press";
import pressPage from "./pressPage";
import cookies from "./cookies";

export default combineReducers({
  auth,
  navigations,
  page,
  navigation_pages: navigationPages,
  media,
  components,
  documents,
  employees,
  news,
  news_page: newsPage,
  news_categories: newsCategories,
  jobs,
  job_page: jobPage,
  job_categories: jobCategories,
  job_companies: jobCompanies,
  breadcrumb,
  press,
  press_page: pressPage,
  cookies,
});
