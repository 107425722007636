import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import { Formik, Form } from "formik";
import {
  createNews,
  getNewsById,
  setNewNews,
  updateNews,
} from "../../common/actions/news";
import { getNewsCategories } from "../../common/actions/newsCategories";
import { NewsSchema } from "../schemas";
import requireAuth from "../components/_hocs/requireAuth";
import { defaultFailureNotification } from "../../common/helpers/UIkitNotifications";

const Seo = loadable(() => import("../components/news-creator/Seo"));
const Dates = loadable(() => import("../components/news-creator/Dates"));
const Header = loadable(() => import("../components/news-creator/Header"));
const Social = loadable(() => import("../components/news-creator/Social"));
const Preview = loadable(() => import("../components/news-creator/Preview"));
const Content = loadable(() => import("../components/news-creator/Content"));
const Gallery = loadable(() => import("../components/news-creator/Gallery"));
const FormButtonNav = loadable(() => import("../components/FormButtonNav"));

function NewsCreator({
  news,
  getNewsById,
  setNewNews,
  createNews,
  updateNews,
  location,
  history,
  auth,
}) {
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get("id")) {
      getNewsById(params.get("id"));
    } else {
      setNewNews();
    }
  }, []);

  return (
    <main>
      <section className="uk-section uk-section-default">
        <div className="uk-container">
          <h3 className="uk-flex uk-flex-middle">
            <div>
              <button
                onClick={() => history.goBack()}
                className="uk-icon-button uk-margin-small-right"
                data-uk-icon="arrow-left"
                data-uk-tooltip={
                  news.meta.title
                    ? "Zurück zur " + news.meta.title
                    : "Zurück zur Startseite"
                }
                aria-label={
                  news.meta.title
                    ? "Zurück zur " + news.meta.title
                    : "Zurück zur Startseite"
                }
              />
            </div>
            <span>News-Creator</span>
          </h3>
          <Formik
            initialValues={{
              ...news,
              author: news.author ? news.author : auth,
            }}
            enableReinitialize={true}
            validationSchema={NewsSchema}
            onSubmit={(values, actions) => {
              if (!values.gallery) values.gallery = [];
              if (values.category === "") values.category = undefined;

              if (values._id) {
                updateNews(values).then((n) => {
                  if (n) {
                    actions.setSubmitting(false);
                    history.goBack();
                  }
                });
              } else {
                createNews(values).then((n) => {
                  if (n) {
                    actions.setSubmitting(false);
                    history.goBack();
                  }
                });
              }
            }}
          >
            {(props) => {
              if (
                props.isSubmitting &&
                props.isValidating &&
                Object.keys(props.errors).length > 0
              ) {
                Object.keys(props.errors).forEach((key) => {
                  if (typeof props.errors[key] === "string") {
                    defaultFailureNotification(`${key}: ${props.errors[key]}.`);
                  } else {
                    Object.keys(props.errors[key]).forEach((subKey) => {
                      defaultFailureNotification(
                        `${subKey}: ${props.errors[key][subKey]}.`
                      );
                    });
                  }
                });
              }
              return (
                <Form>
                  <ul
                    className="uk-accordion"
                    data-uk-accordion="multiple: true"
                  >
                    <Seo {...props} />
                    {/*<Sitemap {...props} />*/}
                    <Dates {...props} />
                    <Header {...props} />
                    <Content {...props} />
                    <Preview {...props} />
                    <Gallery {...props} />
                    <Social {...props} />
                  </ul>
                  <FormButtonNav resetFunction={history.goBack} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </main>
  );
}

function mapStateToProps({ news_page, auth }) {
  return { news: news_page, auth };
}

const mapDispatchToProps = {
  getNewsById,
  setNewNews,
  createNews,
  updateNews,
};

export default {
  loadData: ({ dispatch }) => {
    return {
      newsCategories: dispatch(getNewsCategories()),
    };
  },
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(requireAuth(withRouter(NewsCreator))),
};
