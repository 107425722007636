import { GET_NEWS_CATEGORIES } from "./types";
import { apiReport, errorReport } from "../helpers/reporter";

export const getNewsCategories = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/newsCategories");

    await dispatch({
      type: GET_NEWS_CATEGORIES,
      payload: response.data.payload
    });

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createNewsCategories = category => async (
  dispatch,
  getState,
  api
) => {
  try {
    const response = await api.post("/api/v1/newsCategories", category);

    await dispatch(getNewsCategories());

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removeNewsCategory = category => async (
  dispatch,
  getState,
  api
) => {
  try {
    await api.delete(`/api/v1/newsCategories/${category._id}`);

    await dispatch(getNewsCategories());
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
