import { GET_ME } from "./types";
import { errorReport } from "../helpers/reporter";
import {
  defaultFailureNotification,
  defaultSuccessNotification
} from "../helpers/UIkitNotifications";

export const me = () => async (dispatch, getState, api) => {
  try {
    if (
      api.defaults.headers.cookie &&
      api.defaults.headers.cookie.includes("token=")
    ) {
      const response = await api.get("/api/v1/accounts/me");

      await dispatch({
        type: GET_ME,
        payload: response.data.payload || response.data.success
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ME,
      payload: err.response.data.payload || err.response.data.success
    });

    errorReport(err, getState().auth);
  }
};

export const register = body => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/accounts/register", body);

    defaultSuccessNotification(response.data.message);

    return response.data.success;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      defaultFailureNotification(err.response.data.message);
    }
  }
};

export const login = credentials => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/accounts/login", credentials);

    await dispatch({
      type: GET_ME,
      payload: response.data.payload || response.data.success
    });

    return response.data.payload;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      defaultFailureNotification(err.response.data.message);
    }
  }
};

export const logout = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/accounts/logout");

    return response.data.payload;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      defaultFailureNotification(err.response.data.message);
    }
  }
};
