import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import { imageSrcUrl } from "../../common/helpers/media";
import {
  getJobs,
  getJobById,
  createJobApplication,
} from "../../common/actions/jobs";
import { defaultFailureNotification } from "../../common/helpers/UIkitNotifications";
import moment from "moment";

const SimpleHeader = loadable(() => import("../varieties/SimpleHeader"));
const JobForm = loadable(() => import("../components/JobForm"));

const JobDetails = ({
  jobPage,
  getJobById,
  getJobs,
  createJobApplication,
  location,
  staticContext = {},
}) => {
  const [redirect, setRedirect] = useState("");
  const [salutation, setSalutation] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getJobById(location.pathname)
      .then((response) => {
        if (response.status === 404) {
          setRedirect("/404");
        } else {
          getJobs({
            category: response.data.payload.category._id,
            select:
              "title teaser category author media url publishedAt category company",
            limit: 4,
            sort: "-publishedAt",
            publishedAt: { lte: moment(new Date()).format("YYYY-MM-DD")}
          });
        }
      })
      .catch(() => {
        defaultFailureNotification("Seite nicht gefunden.");
        setRedirect("/404");
      });
  }, [location.pathname]);

  useEffect(() => {
    setSalutation(!jobPage?.category?.label?.includes("usbildung"));
  }, [jobPage]);

  if (redirect) {
    staticContext.notFound = true;
    return <Redirect to={redirect} />;
  }

  function head() {
    return (
      <Helmet>
        <title>{jobPage.meta.title}</title>
        <meta property="description" content={jobPage.meta.description} />
        <meta property="og:type" content={jobPage.social.ogType || "website"} />
        <meta property="og:title" content={jobPage.social.ogTitle} />
        <meta
          property="og:description"
          content={jobPage.social.ogDescription}
        />
        <meta
          property="og:image"
          content={imageSrcUrl(jobPage.social.ogImage)}
        />
      </Helmet>
    );
  }

  return (
    <>
      {head()}
      <main>
        <SimpleHeader
          index={0}
          context={[
            `<h1>${jobPage.title}</h1>`,
            jobPage.header,
            jobPage.publishedAt,
          ]}
        />
        <section className="uk-section uk-section-default">
          <div className="uk-container uk-container-expand">
            <div data-uk-grid="">
              <div className="uk-width-1-4@s" />
              <div className="uk-width-expand">
                <article
                  dangerouslySetInnerHTML={{ __html: jobPage.content }}
                />
                {salutation ? (
                  <h3>Bewerben Sie sich jetzt online als:</h3>
                ) : (
                  <h3>Bewirb Dich jetzt online als:</h3>
                )}
                <JobForm
                  job={jobPage}
                  salutation={salutation}
                  createJobApplication={createJobApplication}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

function mapStateToProps({ job_page }) {
  return { jobPage: job_page };
}

const mapDispatchToProps = {
  getJobs,
  getJobById,
  createJobApplication,
};

export default {
  loadData: ({ dispatch, url }) => {
    return {
      getJobById: dispatch(getJobById(url)),
    };
  },
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(JobDetails)),
};
