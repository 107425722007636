import { GET_NAVIGATIONS, GET_NAVIGATIONS_PAGES } from "./types";
import { apiReport, errorReport } from "../helpers/reporter";

export const getNavigations = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/navigations");

    await dispatch({ type: GET_NAVIGATIONS, payload: response.data.payload });
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createNode = ({ node }) => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/nodes", node);

    await dispatch(getNavigations());

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const updateNode = ({ node }) => async (dispatch, getState, api) => {
  try {
    const response = await api.put(`/api/v1/nodes/${node._id}`, node);

    await dispatch(getNavigations());

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removeNode = ({ id }) => async (dispatch, getState, api) => {
  try {
    const response = await api.delete(`/api/v1/nodes/${id}`);

    await dispatch(getNavigations());

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const getPagesForNavigation = () => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/pages", {
      params: { select: "_id,url", limit: 1000 }
    });

    await dispatch({
      type: GET_NAVIGATIONS_PAGES,
      payload: response.data.payload
    });
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
