export function generateArticleStructureData({
  headline,
  images,
  datePublished,
  dateModified,
  author,
  articleBody,
  articleSection,
  logo,
  teaser,
  url
}) {
  return `{
		"@context": "http://schema.org",
		"@type": "NewsArticle",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": "https://google.com/article"
		},
		"headline": "${headline}",
		"image": ${Array.isArray(images) ? images : '"' + images + '"'},
		"datePublished": "${datePublished}",
		"dateModified": "${dateModified}",
		"author": {
			"@type": "Person",
			"name": "${author}"
		},
		"articleBody": "${articleBody}",
		"articleSection": "${articleSection}",
		"publisher": {
			"@type": "Organization",
			"name": "${author}",
			"logo": {
				"@type": "ImageObject",
				"url": "${logo}"
			}
		},
		"description": "${teaser}",
		"url": "${url}"
  }`;
}
