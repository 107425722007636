import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import SearchBar from "../components/SearchBar";

import { getJobs } from "../../common/actions/jobs";
import requireAuth from "../components/_hocs/requireAuth";

const ListItemCard = loadable(() =>
  import("../components/jobs-overview/ListItemCard")
);

function JobsOverview({ jobs, getJobs }) {
  useEffect(() => {
    getJobs();
  }, []);

  function searchFunction(query) {
    if (query) {
      const params = {
        or: [
          { title: { regex: query } },
          { cipher: { regex: query } },
          { teaser: { regex: query } },
          { city: { regex: query } },
        ],
      };

      getJobs(params);
    } else {
      getJobs();
    }
  }

  return (
    <main>
      <section className="uk-section uk-section-default">
        <div className="uk-container">
          <h3 className="uk-flex uk-flex-middle">
            <div>
              <Link
                to="/"
                className="uk-icon-button uk-margin-small-right"
                data-uk-tooltip="Zurück zur Startseite"
                aria-label="Zurück zur Startseite"
              >
                <i data-uk-icon="arrow-left" />
              </Link>
            </div>
            <span>Jobs-Creator</span>
          </h3>
          <div className="uk-placeholder uk-text-center uk-border-rounded">
            {jobs.length
              ? "Sie haben bereits Karriere Anzeigen erstellt und wollen noch mehr "
              : "Noch keine Karriere Anzeigen vorhanden, lege am besten gleich eine an "}
            <span data-uk-icon="arrow-right" />{" "}
            <Link
              to="/job-creator"
              className="uk-margin-small-right"
              data-uk-tooltip="Erstellen"
            >
              <i className="uk-icon-button" data-uk-icon="plus" />
            </Link>
          </div>
          <SearchBar searchFunction={searchFunction} />
          <ul className="uk-accordion" data-uk-accordion="multiple: true">
            {jobs.map((item) => (
              <ListItemCard key={item._id} job={item} />
            ))}
          </ul>
        </div>
      </section>
    </main>
  );
}

function mapStateToProps({ jobs }) {
  return { jobs };
}

const mapDispatchToProps = {
  getJobs,
};

export default {
  loadData: ({ dispatch }) => {
    return {
      getJobs: dispatch(getJobs()),
    };
  },
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(requireAuth(withRouter(JobsOverview))),
};
