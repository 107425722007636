import { SET_NEW_NEWS, GET_NEWS, GET_NEWS_PAGE } from "./types";
import { apiReport, errorReport, supportReport } from "../helpers/reporter";
import qs from "qs";

export const setNewNews = () => async (dispatch, getState) => {
  dispatch({ type: SET_NEW_NEWS });
  supportReport(getState().auth, "Leere News-Seite gesetzt.");
};

export const getNews = params => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/news", { params,
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });

    await dispatch({ type: GET_NEWS, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const getNewsById = id => async (dispatch, getState, api) => {
  try {
    let _id = id;
    let options = {};

    if (id.startsWith("/")) {
      _id = "id";
      options = {
        params: { url: id }
      };
    }

    const response = await api.get(`/api/v1/news/${_id}`, options);

    await dispatch({ type: GET_NEWS_PAGE, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createNews = news => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/news", news);

    await dispatch({ type: GET_NEWS_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const updateNews = news => async (dispatch, getState, api) => {
  try {
    const response = await api.put(`/api/v1/news/${news._id}`, news);

    await dispatch({ type: GET_NEWS_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removeNews = news => async (dispatch, getState, api) => {
  try {
    return await api.delete(`/api/v1/news/${news._id}`);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
