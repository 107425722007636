export const defaultSuccessNotification = message => {
  return window.UIkit.notification({
    message: message,
    status: "success",
    group: "success"
  });
};

export const defaultFailureNotification = message => {
  return window.UIkit.notification({
    message: message,
    status: "danger",
    group: "danger"
  });
};
