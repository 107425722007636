import { SET_NEW_NEWS, GET_NEWS_PAGE } from "../actions/types";

const initialState = {
  url: "",
  type: "news",
  active: false,
  deletable: true,
  meta: {
    title: "",
    description: ""
  },
  sitemap: {
    changefreq: "",
    priority: 0.5
  },
  social: {
    ogTitle: "",
    ogDescription: "",
    ogType: "",
    ogImage: {}
  },
  header: {},
  media: {},
  title: "",
  subTitle: "",
  teaser: "",
  content: "",
  gallery: [],
  category: "",
  author: "",
  publishedAt: "",
  publishedTo: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_NEWS:
      return initialState;
    case GET_NEWS_PAGE:
      return { ...action.payload };
    default:
      return state;
  }
};
