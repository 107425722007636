export const runtimeConfig =
  typeof window !== "undefined"
    ? {
        // client
        ENV: window.env.NODE_ENV,
        RAZZLE_GTM: window.env.RAZZLE_GTM,
        NEWS_URL_PREFIX: window.env.NEWS_URL_PREFIX,
        PRESS_URL_PREFIX: window.env.NEWS_URL_PREFIX,
        DATE_URL_PREFIX: window.env.NEWS_URL_PREFIX,
        JOB_URL_PREFIX: window.env.JOB_URL_PREFIX,
        LOGO: window.env.LOGO,
        NL_GROUP_ID: window.env.NL_GROUP_ID,
        NL_FORM_ID: window.env.NL_FORM_ID,
        NL_CLIENT_ID: window.env.NL_CLIENT_ID,
        NL_CLIENT_SECRET: window.env.NL_CLIENT_SECRET
      }
    : {
        // server
        ENV: process.env.NODE_ENV,
        RAZZLE_GTM: process.env.RAZZLE_GTM,
        NEWS_URL_PREFIX: process.env.RAZZLE_NEWS_URL_PREFIX,
        PRESS_URL_PREFIX: process.env.RAZZLE_PRESS_URL_PREFIX,
        DATE_URL_PREFIX: process.env.RAZZLE_DATE_URL_PREFIX,
        JOB_URL_PREFIX: process.env.RAZZLE_JOB_URL_PREFIX,
        LOGO: process.env.RAZZLE_LOGO,
        NL_GROUP_ID: process.env.NL_GROUP_ID,
        NL_FORM_ID: process.env.NL_FORM_ID,
        NL_CLIENT_ID: process.env.NL_CLIENT_ID,
        NL_CLIENT_SECRET: process.env.NL_CLIENT_SECRET
      };
