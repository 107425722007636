import { SET_NEW_PRESS, GET_PRESS_PAGE } from "../actions/types";

const initialState = {
  url: "",
  type: "press",
  active: false,
  deletable: true,
  media: {},
  title: "",
  teaser: "",
  medium: "",
  category: "",
  author: "",
  publishedAt: "",
  publishedTo: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_PRESS:
      return initialState;
    case GET_PRESS_PAGE:
      return { ...action.payload };
    default:
      return state;
  }
};
