import { SET_NEW_PAGE, SET_PAGE_COMPONENT_CHANGES, GET_PAGE } from "./types";
import { apiReport, errorReport } from "../helpers/reporter";

export const setNewPage = () => async (dispatch) => {
  dispatch({ type: SET_NEW_PAGE });
};

export const setPageComponentChanges = (payload) => async (dispatch) => {
  dispatch({ type: SET_PAGE_COMPONENT_CHANGES, payload });
};

export const getPage = (id) => async (dispatch, getState, api) => {
  try {
    let _id = id;
    let options = {};

    if (id.startsWith("/")) {
      _id = "id";
      options = {
        params: { url: id },
      };
    }

    const response = await api.get(`/api/v1/pages/${_id}`, options);

    await dispatch({ type: GET_PAGE, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
    return err;
  }
};

export const createPage = (page) => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/pages", page);

    await dispatch({ type: GET_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const updatePage = (page, noGet = false) => async (
  dispatch,
  getState,
  api
) => {
  try {
    const response = await api.put(`/api/v1/pages/${page._id}`, page);

    // get page not needed
    if (!noGet) {
      await dispatch({ type: GET_PAGE, payload: response.data.payload });
    }

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const searchPage = (query) => async (dispatch, getState, api) => {
  try {
    const response = await api.get(`/api/v1/pages/search`, {
      params: { query },
    });

    return response?.data?.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
