import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import { Formik, Form } from "formik";
import qs from "qs";
import {
  createJob,
  getJobById,
  setNewJob,
  updateJob,
} from "../../common/actions/jobs";
import { getJobCategories } from "../../common/actions/jobCategories";
import { JobSchema } from "../schemas";
import requireAuth from "../components/_hocs/requireAuth";
import { defaultFailureNotification } from "../../common/helpers/UIkitNotifications";

const Seo = loadable(() => import("../components/job-creator/Seo"));
const Dates = loadable(() => import("../components/job-creator/Dates"));
const Header = loadable(() => import("../components/job-creator/Header"));
const Social = loadable(() => import("../components/job-creator/Social"));
const Content = loadable(() => import("../components/job-creator/Content"));
const FormButtonNav = loadable(() => import("../components/FormButtonNav"));

function JobCreator({
  job,
  getJobById,
  setNewJob,
  createJob,
  updateJob,
  location,
  history,
}) {
  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (params?.id) {
      // Lade Karriere Seite zum editieren
      getJobById(params.id);
    } else {
      setNewJob();
    }
  }, [location]);

  return (
    <main>
      <section className="uk-section uk-section-default">
        <div className="uk-container">
          <h3 className="uk-flex uk-flex-middle">
            <div>
              <button
                onClick={() => history.goBack()}
                className="uk-icon-button uk-margin-small-right"
                data-uk-icon="arrow-left"
                data-uk-tooltip={
                  job.meta.title
                    ? "Zurück zur " + job.meta.title
                    : "Zurück zur Startseite"
                }
                aria-label={
                  job.meta.title
                    ? "Zurück zur " + job.meta.title
                    : "Zurück zur Startseite"
                }
              />
            </div>
            <span>Job-Creator</span>
          </h3>
          <Formik
            initialValues={{
              ...job,
            }}
            enableReinitialize={true}
            validationSchema={JobSchema}
            onSubmit={(values, actions) => {
              if (values._id) {
                updateJob(values);
              } else {
                createJob(values);
              }
              actions.setSubmitting(false);
              history.goBack();
            }}
          >
            {(props) => {
              if (
                props.isSubmitting &&
                props.isValidating &&
                Object.keys(props.errors).length > 0
              ) {
                defaultFailureNotification("Überprüfen Sie Ihre Eingabe.");
              }
              return (
                <Form>
                  <ul
                    className="uk-accordion"
                    data-uk-accordion="multiple: true"
                  >
                    <Seo {...props} />
                    <Dates {...props} />
                    <Header {...props} />
                    <Content {...props} />
                    <Social {...props} />
                  </ul>
                  <FormButtonNav resetFunction={history.goBack} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </main>
  );
}

function mapStateToProps({ job_page, auth }) {
  return { job: job_page, auth };
}

const mapDispatchToProps = {
  getJobById,
  setNewJob,
  createJob,
  updateJob,
};

export default {
  loadData: ({ dispatch }) => {
    return {
      jobCategories: dispatch(getJobCategories()),
    };
  },
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(requireAuth(withRouter(JobCreator))),
};
