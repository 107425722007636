import { SET_NEW_PRESS, GET_PRESS, GET_PRESS_PAGE } from "./types";
import { apiReport, errorReport, supportReport } from "../helpers/reporter";

export const setNewPress = () => async (dispatch, getState) => {
  dispatch({ type: SET_NEW_PRESS });
  supportReport(getState().auth, "Leere Presse-Seite gesetzt.");
};

export const getPress = params => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/press", { params });

    await dispatch({ type: GET_PRESS, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const getPressById = id => async (dispatch, getState, api) => {
  try {
    let _id = id;
    let options = {};

    if (id.startsWith("/")) {
      _id = "id";
      options = {
        params: { url: id }
      };
    }

    const response = await api.get(`/api/v1/press/${_id}`, options);

    await dispatch({ type: GET_PRESS_PAGE, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createPress = press => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/press", press);

    await dispatch({ type: GET_PRESS_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const updatePress = press => async (dispatch, getState, api) => {
  try {
    const response = await api.put(`/api/v1/press/${press._id}`, press);

    await dispatch({ type: GET_PRESS_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removePress = press => async (dispatch, getState, api) => {
  try {
    return await api.delete(`/api/v1/press/${press._id}`);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
