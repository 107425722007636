import { SET_SHOW_COOKIES_CONS } from "../actions/types";

const initialState = {
  showCookiesCons: false,
  cookiesModalPage: 1,
}

  export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_COOKIES_CONS:{
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
