import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect, withRouter } from "react-router-dom";
import moment from "moment";
import loadable from "@loadable/component";
import { imageSrcUrl } from "../../common/helpers/media";
import { getNews, getNewsById } from "../../common/actions/news";
import { defaultFailureNotification } from "../../common/helpers/UIkitNotifications";
import { generateArticleStructureData } from "../structures/Article";

const SimpleHeader = loadable(() => import("../varieties/SimpleHeader"));
const SimpleSlider = loadable(() => import("../varieties/SimpleSlider"));

const NewsDetails = ({
  newsPage,
  news,
  getNewsById,
  getNews,
  location,
  staticContext = {},
}) => {
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getNewsById(location.pathname)
      .then((response) => {
        if (response.status === 404) {
          setRedirect("/404");
        } else {
          getNews({
            category: response.data.payload.category._id,
            select: "title teaser category author media url publishedAt",
            limit: 4,
            sort: "-publishedAt",
          });
        }
      })
      .catch(() => {
        defaultFailureNotification("Seite nicht gefunden.");
        setRedirect("/404");
      });
  }, [location.pathname]);

  if (redirect) {
    staticContext.notFound = true;
    return <Redirect to={redirect} />;
  }

  function head() {
    return (
      <Helmet>
        <title>{newsPage.meta.title}</title>
        <meta property="description" content={newsPage.meta.description} />
        <meta
          property="og:type"
          content={newsPage.social.ogType || "website"}
        />
        <meta property="og:title" content={newsPage.social.ogTitle} />
        <meta
          property="og:description"
          content={newsPage.social.ogDescription}
        />
        <meta
          property="og:image"
          content={imageSrcUrl(newsPage.social.ogImage)}
        />
        {/*Structured data*/}
        <script type="application/ld+json">
          {generateArticleStructureData({
            headline: newsPage.title,
            images: imageSrcUrl(newsPage.media).replace(
              "http://localhost:3002",
              "https://api.kutter.de"
            ),
            datePublished: newsPage.publishedAt,
            dateModified: newsPage.updatedAt,
            author: "Kutter News",
            articleBody: newsPage.content.replace(/<\/?[^>]+(>|$)/g, ""),
            articleSection: newsPage.category.label,
            logo: "/kutter-logo.png",
            teaser: newsPage.teaser,
            url: location.pathname,
          })}
        </script>
      </Helmet>
    );
  }

  return (
    <>
      {head()}
      <main>
        <SimpleHeader
          index={0}
          context={[
            `<h1>${newsPage.title}</h1>`,
            newsPage.header,
            newsPage.publishedAt,
          ]}
        />
        <section className="uk-section uk-section-default">
          <div className="uk-container uk-container-expand">
            <div data-uk-grid="">
              <div className="uk-width-1-4@s" />
              <article
                className="uk-width-expand"
                dangerouslySetInnerHTML={{ __html: newsPage.content }}
              />
            </div>
          </div>
        </section>
        <SimpleSlider index={2} context={newsPage.gallery} />
        <section className="uk-section uk-section-default">
          <div className="uk-container uk-container-expand">
            <div className="uk-grid" data-uk-grid="">
              <div className="uk-width-1-4@m" />
              <div>
                <Link
                  to="/aktuelles"
                  className="uk-link uk-link-reset uk-button-text uk-h2"
                >
                  Zu allen News
                </Link>
              </div>
            </div>
          </div>
        </section>
        {news.filter((data) => data._id !== newsPage._id).length > 0 && (
          <section className="uk-section uk-section-muted">
            <div className="uk-container uk-container-expand">
              <div className="uk-grid" data-uk-grid="">
                <div className="uk-width-1-4@m" />
                <div className="uk-width-expand">
                  <h2>
                    Ähnliche oder weitere News
                  </h2>
                  {news
                    .filter((data) => data._id !== newsPage._id)
                    .map((data) => (
                      <div
                        key={data._id}
                        className="uk-width-1-1 uk-margin-large uk-border-bottom"
                      >
                        <p className="uk-border-bottom uk-padding-small uk-padding-remove-horizontal">
                          {moment(data.publishedAt)
                            .locale("de")
                            .format("dddd, DD.MM.YYYY")}
                        </p>
                        <div
                          className="uk-grid uk-margin-medium"
                          data-uk-grid=""
                        >
                          <div className="uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s uk-flex-last@s">
                            {data.media && (
                              <img
                                className="lazyload"
                                width={"100%"}
                                data-src={imageSrcUrl(data.media)}
                                alt={data.media.alt}
                              />
                            )}
                          </div>
                          <div className="uk-width-expand uk-flex uk-flex-column">
                            <h2 className="uk-margin-small">{data.title}</h2>
                            <p>{data.teaser}</p>
                            <Link
                              to={data.url}
                              className="uk-button uk-button-large uk-button-primary uk-margin-auto-top uk-width-1-4@xl uk-width-1-3@l uk-width-1-2@m uk-text-uppercase"
                            >
                              Zum Artikel
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
};

function mapStateToProps({ news_page, news }) {
  return { newsPage: news_page, news };
}

const mapDispatchToProps = {
  getNewsById,
  getNews,
};

export default {
  loadData: ({ dispatch, url }) => {
    return {
      getNewsById: dispatch(getNewsById(url)),
    };
  },
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(NewsDetails)),
};
