import React from "react";
import loadable from "@loadable/component";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import { me } from "./actions/auth";
import { getNavigations } from "./actions/navigation";

const ControlPanel = loadable(() =>
  import("../backend/components/ControlPanel")
);

const Backend = ({ auth, route }) => {
  return (
    <>
      {renderRoutes(route.routes)}
      {auth && typeof window !== "undefined" ? <ControlPanel /> : null}
    </>
  );
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default {
  loadData: ({ dispatch }) => {
    return {
      navigations: dispatch(getNavigations()),
      me: dispatch(me()),
    };
  },
  component: connect(mapStateToProps)(Backend),
};
