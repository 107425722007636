import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Helmet } from "react-helmet";
import { getPage, updatePage } from "../../common/actions/page";
import {
  defaultSuccessNotification,
  defaultFailureNotification
} from "../../common/helpers/UIkitNotifications";
import { imageSrcUrl } from "../../common/helpers/media";

const VarietySelector = loadable(() => import("../components/VarietySelector"));
const ComponentModal = loadable(() =>
  import("../../backend/components/ComponentModal")
);

const Page = ({
  auth,
  page,
  getPage,
  updatePage,
  location,
  staticContext = {}
}) => {
  const [redirect, setRedirect] = useState("");

  function loadPage() {
    return getPage(location.pathname)
      .then(response => {
        if (response.status === 404) {
          setRedirect("/404");
        }
      })
      .catch(() => {
        defaultFailureNotification("Seite nicht gefunden.");
        setRedirect("/404");
      });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (page.changes) {
      window.UIkit.modal
        .confirm(
          "Sie haben noch ungesicherte Änderungen. Möchten Sie diese Änderungen sichern?",
          {
            labels: { ok: "Änderungen sichern", cancel: "Änderungen verwerfen" }
          }
        )
        .then(
          () => updatePage(page, true).then(loadPage),
          () => {
            defaultSuccessNotification("Änderungen wurden verworfen.");
            loadPage();
          }
        );
      // const result = confirm(
      //   "Sie haben noch ungesicherte Änderungen. Möchten Sie diese Änderungen sichern?"
      // );
      // if (result) {
      //   updatePage(page).then(loadPage);
      // } else {
      //   defaultSuccessNotification("Änderungen wurden verworfen.");
      // }
    } else loadPage();
  }, [location.pathname]);

  if (redirect) {
    staticContext.notFound = true;
    return <Redirect to={redirect} />;
  }

  function head() {
    return (
      <Helmet>
        <title>{page.meta.title}</title>
        <meta name="description" content={page.meta.description} />
        <meta property="og:type" content={page.social.ogType || "website"} />
        <meta property="og:title" content={page.social.ogTitle} />
        <meta property="og:description" content={page.social.ogDescription} />
        <meta property="og:image" content={imageSrcUrl(page.social.ogImage)} />
      </Helmet>
    );
  }

  function emptyPage() {
    return (
      <section
        className="uk-section uk-section-default uk-flex uk-flex-middle"
        data-uk-height-viewport="expand: true;"
      >
        <div className="uk-container uk-container-expand">
          <div className="uk-placeholder uk-text-center uk-border-rounded">
            Noch keine Komponente vorhanden, lege am besten gleich eine an{" "}
            <span data-uk-icon="arrow-right" />
            <ComponentModal index={0} />
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      {head()}
      <main>
        <VarietySelector auth={auth} components={page.components} />
        {auth && page._id && !page.components.length && emptyPage()}
        {auth && !page._id && !page.components.length && (
          <section
            className="uk-section uk-section-default uk-flex uk-flex-middle"
            data-uk-height-viewport="expand: true;"
          >
            <div className="uk-container uk-container-expand">
              <div className="uk-placeholder uk-text-center uk-border-rounded">
                Diese Seite ist nicht vorhanden. Erstellen Sie erst eine neue
                Seite.
                <span data-uk-icon="arrow-right" />
                <Link to={"/pages"} data-uk-icon="plus" />
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
};

function mapStateToProps({ page, auth }) {
  return { page, auth };
}

const mapDispatchToProps = {
  getPage,
  updatePage
};

export default {
  loadData: ({ dispatch, url }) => {
    return {
      getPage: dispatch(getPage(url))
    };
  },
  component: connect(mapStateToProps, mapDispatchToProps)(withRouter(Page))
};
