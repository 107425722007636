import React from "react";
import { Formik, FastField, ErrorMessage } from "formik";
import * as Yup from "yup";

const searchSchema = Yup.object().shape({
  query: Yup.string().min(3, "Mindestens 3 Zeichen")
});

function SearchBar({ searchFunction }) {
  return (
    <Formik
      initialValues={{ query: "" }}
      validationSchema={searchSchema}
      onReset={(values, actions) => {
        actions.resetForm();
      }}
      onSubmit={(values, actions) => {
        if (values.query.length > 2) {
          searchFunction(values.query);
        }
        if (!values.query.length) searchFunction();
        actions.setSubmitting(false);
      }}
    >
      {props => {
        return (
          <form
            className="uk-form-stacked"
            onSubmit={e => {
              e.stopPropagation();
              props.handleSubmit(e);
            }}
            onReset={e => {
              e.stopPropagation();
              props.handleReset();
            }}
          >
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="query">
                Suche
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-expand">
                  <button
                    className="uk-form-icon uk-form-icon-flip"
                    data-uk-icon="icon: search"
                    type="submit"
                  />
                  <FastField
                    className="uk-input uk-border-rounded"
                    name="query"
                    placeholder="Suche (min. 3 Zeichen) ..."
                  />
                </div>
                <ErrorMessage name="query">
                  {msg => <div className="uk-text-danger">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default SearchBar;
