import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const pagesWithExtendedRole = [
  { path: "/news-creator", role: "news" },
  { path: "/press-creator", role: "news" },
  { path: "/news-press-dates-overview", role: "news" },
  { path: "/job-creator", role: "jobs" },
  { path: "/jobs-overview", role: "jobs" }
];

export default ChildComponent => {
  const RequireAuth = props => {
    switch (props.auth) {
      case false:
        return <Redirect to="/" />;
      case null:
        return <div>Loading...</div>;
      default:
        // extended logic for pages with extended roles
        const checkForRole = pagesWithExtendedRole.find(
          x => x.path === props.location.pathname
        );

        if (checkForRole) {
          if (
            !Array.isArray(props.auth.role) ||
            !props.auth.role.includes(checkForRole.role)
          ) {
            console.info("Permission denied");
            return <Redirect to="/" />;
          }
        }

        return <ChildComponent {...props} />;
    }
  };

  function mapStateToProps({ auth }) {
    return { auth };
  }

  return connect(mapStateToProps)(RequireAuth);
};
