import { stringify } from "qs";

/**
 * Function to returns shortcut of mimetype
 * @param mimetype
 * @returns {string|*}
 */
export const getType = function getTypeFromMimetype(mimetype) {
  if (mimetype && mimetype.includes("image")) return "image";
  else if (mimetype && mimetype.includes("video")) return "video";
  return mimetype;
};

/**
 * Create a parametrized image link for API Server
 * @param context
 * @returns {URL || String}
 */
export const imageSrcUrl = function generateImageSrcUrlFromContext(context) {
  if (!context || !Object.keys(context).length) {
    return "";
  }
  let url = `${process.env.API_URL ||
    (typeof window !== "undefined" && window.API_URL)}/v1/image/${context.z}_${
    context.id
  }?`;

  let params = {};

  params.f = context.mimetype.replace("image/", "");

  if (params.f === "png") {
    params.f = "jpeg";
  }

  if (process.env.WEBP === "true") {
    params.f = "webp";
  }

  if (typeof window !== "undefined" && window.WEBP === "true") {
    params.f = "webp";
  }

  if (context.x) params.x = context.x;
  if (context.y) params.y = context.y;
  if (context.z) params.z = context.z;
  if (context.u) params.u = context.u;
  if (context.w) params.w = context.w;
  if (context.h) params.h = context.h;
  return url + stringify(params);
};

/**
 * Create a parametrized video link for API Server
 * @param context
 * @returns {URL || String}
 */
export const videoSrcUrl = function generateVideoSrcUrlFromContext(context) {
  const url = `${process.env.API_URL ||
    (typeof window !== "undefined" && window.API_URL)}/v1/video/${context.id}?`;

  let params = {};

  params.f = context.mimetype.replace("video/", "");

  return url + stringify(params);
};
