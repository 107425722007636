import qs from "qs";
import { SET_NEW_JOB, GET_JOBS, GET_JOB_PAGE } from "./types";
import { apiReport, errorReport } from "../helpers/reporter";

export const setNewJob = () => async (dispatch) => {
  dispatch({ type: SET_NEW_JOB });
};

export const getJobs = (params) => async (dispatch, getState, api) => {
  try {
    const response = await api.get("/api/v1/jobs", {
      params: {
        select: "social title teaser category city company cipher",
        limit: 10000,
        ...params,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });

    await dispatch({ type: GET_JOBS, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const getJobById = (id) => async (dispatch, getState, api) => {
  try {
    let _id = id;
    let options = {};

    if (id.startsWith("/")) {
      _id = "id";
      options = {
        params: { url: id },
      };
    }

    const response = await api.get(`/api/v1/jobs/${_id}`, options);

    await dispatch({ type: GET_JOB_PAGE, payload: response.data.payload });

    return response;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createJob = (job) => async (dispatch, getState, api) => {
  try {
    const response = await api.post("/api/v1/jobs", job);

    await dispatch({ type: GET_JOB_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const createJobApplication = (job) => async (
  dispatch,
  getState,
  api
) => {
  try {
    let formData = await new FormData();

    for (const key of Object.keys(job)) {
      if (key !== "files") {
        await formData.append(key, job[key]);
      }
    }

    for (const f of job.files) {
      await formData.append(f.name, f);
    }

    const response = await api.post("/api/v1/jobs/application", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data.payload;
  } catch (err) {
    errorReport(err, getState().auth);
    return err.response.data.payload;
  }
};

export const updateJob = (job) => async (dispatch, getState, api) => {
  try {
    const response = await api.put(`/api/v1/jobs/${job._id}`, job);

    await dispatch({ type: GET_JOB_PAGE, payload: response.data.payload });

    apiReport(response, getState().auth);
  } catch (err) {
    errorReport(err, getState().auth);
  }
};

export const removeJob = (job) => async (dispatch, getState, api) => {
  try {
    await api.delete(`/api/v1/jobs/${job._id}`);

    dispatch(getJobs());
  } catch (err) {
    errorReport(err, getState().auth);
  }
};
