import { SET_NEW_JOB, GET_JOB_PAGE } from "../actions/types";

const initialState = {
  url: "",
  type: "jobs",
  active: false,
  deletable: true,
  meta: {
    title: "",
    description: "",
  },
  sitemap: {
    changefreq: "",
    priority: 0.5,
  },
  social: {
    ogTitle: "",
    ogDescription: "",
    ogType: "",
    ogImage: {},
  },
  header: {},
  title: "",
  cipher: "",
  teaser: "",
  content: "",
  category: "",
  company: "",
  area: "",
  city: "",
  publishedAt: "",
  publishedTo: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_JOB:
      return initialState;
    case GET_JOB_PAGE:
      return { ...action.payload };
    default:
      return state;
  }
};
