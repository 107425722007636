import { GET_BREADCRUMB, SET_BREADCRUMB } from "../actions/types";

export default (state = [], action) => {
  switch (action.type) {
    case GET_BREADCRUMB:
      return state;
    case SET_BREADCRUMB:
      return action.payload;
    default:
      return state;
  }
};
