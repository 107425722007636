import * as Yup from "yup";

export const NewsSchema = Yup.object().shape({
  url: Yup.string().required("Pflichtfeld"),
  type: Yup.string().required("Pflichtfeld"),
  active: Yup.bool(),
  deletable: Yup.bool(),
  meta: Yup.object().shape({
    title: Yup.string().required("Pflichtfeld").max(70, "max. 70 Zeichen"),
    description: Yup.string()
      .required("Pflichtfeld")
      .max(160, "max. 160 Zeichen"),
  }),
  sitemap: Yup.object().shape({
    changefreq: Yup.string(),
    priority: Yup.number(),
  }),
  social: Yup.object().shape({
    ogTitle: Yup.string(),
    ogDescription: Yup.string(),
    ogType: Yup.string(),
    ogImage: Yup.object(),
  }),
  header: Yup.object(),
  media: Yup.object(),
  title: Yup.string().required("Pflichtfeld"),
  subTitle: Yup.string(),
  teaser: Yup.string().required("Pflichtfeld"),
  content: Yup.string().required("Pflichtfeld"),
  gallery: Yup.array(),
  category: Yup.string().required("Pflichtfeld"),
  author: Yup.string(),
  publishedAt: Yup.date().required("Pflichtfeld"),
  publishedTo: Yup.date().nullable(),
});

export const PressSchema = Yup.object().shape({
  url: Yup.string().required("Pflichtfeld"),
  type: Yup.string().required("Pflichtfeld"),
  active: Yup.bool(),
  deletable: Yup.bool(),
  media: Yup.object(),
  title: Yup.string().required("Pflichtfeld"),
  teaser: Yup.string().required("Pflichtfeld"),
  medium: Yup.string(),
  category: Yup.string().required("Pflichtfeld"),
  author: Yup.string(),
  publishedAt: Yup.string().required("Pflichtfeld"),
  publishedTo: Yup.string().nullable(),
});

export const JobSchema = Yup.object().shape({
  url: Yup.string().required("Pflichtfeld"),
  type: Yup.string().required("Pflichtfeld"),
  active: Yup.bool(),
  deletable: Yup.bool(),
  meta: Yup.object().shape({
    title: Yup.string().required("Pflichtfeld").max(70, "max. 70 Zeichen"),
    description: Yup.string()
      .required("Pflichtfeld")
      .max(160, "max. 160 Zeichen"),
  }),
  sitemap: Yup.object().shape({
    changefreq: Yup.string(),
    priority: Yup.number(),
  }),
  social: Yup.object().shape({
    ogTitle: Yup.string(),
    ogDescription: Yup.string(),
    ogType: Yup.string(),
    ogImage: Yup.object(),
  }),
  header: Yup.object(),
  title: Yup.string().required("Pflichtfeld"),
  teaser: Yup.string().required("Pflichtfeld"),
  content: Yup.string().required("Pflichtfeld"),
  category: Yup.string().required("Pflichtfeld"),
  cipher: Yup.string().required("Pflichtfeld"),
  company: Yup.string().required("Pflichtfeld"),
  city: Yup.string().required("Pflichtfeld"),
  publishedAt: Yup.string().required("Pflichtfeld"),
  publishedTo: Yup.string().nullable(),
});

export const SigninSchema = Yup.object().shape({
  email: Yup.string().email("Umgültige E-Mail Adresse").required("Pflichtfeld"),
  password: Yup.string().required("Pflichtfeld"),
});

export const SignupSchema = Yup.object().shape({
  salutation: Yup.string().required("Pflichtfeld"),
  firstName: Yup.string().required("Pflichtfeld"),
  lastName: Yup.string().required("Pflichtfeld"),
  email: Yup.string().email("Umgültige E-Mail Adresse").required("Pflichtfeld"),
  password: Yup.string().min(8, "Mind. 8 Zeichen").required("Pflichtfeld"),
  confirmPassword: Yup.string()
    .required("Pflichtfeld")
    .test("passwords-match", "Passwörter stimmen nicht überein", function (
      value
    ) {
      return this.parent.password === value;
    }),
});

export const JobCompanySchema = Yup.object().shape({
  name: Yup.string().required("Pflichtfeld"),
  street: Yup.string(),
  zip: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  employee: Yup.string().required("Pflichtfeld"),
  phone: Yup.string(),
  retrieves: Yup.array(),
  redirectSuccess: Yup.string().required("Pflichtfeld"),
  redirectFailure: Yup.string().required("Pflichtfeld"),
  email: Yup.string().email("Umgültige E-Mail Adresse").required("Pflichtfeld"),
  terms: Yup.string().required("Pflichtfeld"),
});

export const JobApplicationSchema = Yup.object().shape({
  job: Yup.string().required("Pflichtfeld"),
  salutation: Yup.string(),
  firstName: Yup.string().required("Geben Sie Ihren Vornamen ein"),
  lastName: Yup.string().required("Geben Sie Ihren Nachnamen ein"),
  street: Yup.string().required("Geben Sie Ihre Straße incl. Hausnummer ein"),
  zip: Yup.string().required("Geben Sie Ihre Postleitzahl ein"),
  city: Yup.string().required("Geben Sie Ihre Stadt ein"),
  country: Yup.string().required("Geben Sie Ihr Land ein"),
  email: Yup.string()
    .email("Umgültige E-Mail Adresse")
    .required("Geben Sie Ihre E-mail Adresse ein"),
  phone: Yup.string(),
  birthDay: Yup.string().required("Geben Sie Ihr Geburtsdatum ein"),
  retrieve: Yup.string(),
  files: Yup.array()
    .min(1, "Sie haben keine Bewerbungsunterlagen angehängt.")
    .max(5, "Sie haben bereits 5 Bewerbungsunterlagen angehängt."),
  terms: Yup.bool()
    .required("Pflichtfeld")
    .test(
      "privacy-accepted",
      "Datenschutzbestimmungen müssen akzeptiert werden.",
      function (value) {
        return value === true;
      }
    ),
});
