import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import { Formik, Form } from "formik";
import {
  createPress,
  getPressById,
  setNewPress,
  updatePress
} from "../../common/actions/press";
import { getDocuments } from "../../common/actions/document";
import { PressSchema } from "../schemas";
import requireAuth from "../components/_hocs/requireAuth";
import { defaultFailureNotification } from "../../common/helpers/UIkitNotifications";

const Dates = loadable(() => import("../components/press-creator/Dates"));
const Preview = loadable(() => import("../components/press-creator/Preview"));
const FormButtonNav = loadable(() => import("../components/FormButtonNav"));

function PressCreator({
  press = {},
  documents,
  medium,
  getPressById,
  setNewPress,
  createPress,
  updatePress,
  getDocuments,
  history,
  match,
  auth
}) {
  useEffect(() => {
    if (match.params.id) {
      getPressById(match.params.id);
    } else {
      setNewPress();
    }
  }, []);

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <main>
      <section className="uk-section uk-section-default">
        <div className="uk-container">
          <h3 className="uk-flex uk-flex-middle">
            <div>
              <button
                onClick={() => history.goBack()}
                className="uk-icon-button uk-margin-small-right"
                data-uk-icon="arrow-left"
                data-uk-tooltip={
                  press.title
                    ? "Zurück zur " + press.title
                    : "Zurück zur Startseite"
                }
                aria-label={
                  press.title
                    ? "Zurück zur " + press.title
                    : "Zurück zur Startseite"
                }
              />
            </div>
            <span>Press-Creator</span>
          </h3>
          <Formik
            initialValues={{
              ...press,
              author: press.author ? press.author : auth
            }}
            enableReinitialize={true}
            validationSchema={PressSchema}
            onSubmit={values => {
              if (values._id) {
                updatePress(values).then(n => {
                  if (n) history.goBack();
                });
              } else {
                createPress(values).then(n => {
                  if (n) history.goBack();
                });
              }
            }}
          >
            {props => {
              if (
                props.isSubmitting &&
                props.isValidating &&
                Object.keys(props.errors).length > 0
              ) {
                Object.keys(props.errors).forEach(key => {
                  if (typeof props.errors[key] === "string") {
                    defaultFailureNotification(`${key}: ${props.errors[key]}.`);
                  } else {
                    Object.keys(props.errors[key]).forEach(subKey => {
                      defaultFailureNotification(
                        `${subKey}: ${props.errors[key][subKey]}.`
                      );
                    });
                  }
                });
              }
              return (
                <Form>
                  <ul
                    className="uk-accordion"
                    data-uk-accordion="multiple: true"
                  >
                    <Dates {...props} medium={medium} />
                    <Preview {...props} documents={documents} />
                  </ul>
                  <FormButtonNav resetFunction={history.goBack} />
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </main>
  );
}

function mapStateToProps({ press = [], documents, press_page, auth }) {
  return {
    press: press_page,
    medium: [
      ...new Set(press.map(p => ({ label: p.medium, value: p.medium })))
    ],
    documents,
    auth
  };
}

const mapDispatchToProps = {
  getPressById,
  setNewPress,
  createPress,
  updatePress,
  getDocuments
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuth(withRouter(PressCreator)));
